import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Alert from "./Pages/Alert/Alert";
import Asma from "./Pages/Asma";
import AsmaSPV from "./Pages/AsmaSPV";
import Toplama from "./Pages/Toplama";
import Alert2 from "./Pages/Alert2/Alert2";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import UserContext from "./Context/UserContext";
import Dashboard2 from "./Pages/Dashboard2";
import ReworkHurda from "./Pages/ReworkHurda";
import GovdeOnPage from "./Pages/GovdeOnPage";
import NewDashboard from "./Pages/NewDashboard";
import HomeLayout from "./layouts/HomeLayout";
import StokPage from "./Pages/StokPage";
import ControlHistory from "./Pages/ControlHistory";
import NewReport from "./Pages/NewReport";
import SuspenseContainer from "./Components/SuspenseContainer";
import FirstPage from "./Pages/FirstPage/FirstPage";
import VardiyaPlanPage from "./Pages/VardiyaPlanPage";
import FailureHistory from "./Pages/FailureHistory";
import StatusInfo from "./Pages/StatusInfo";
import TableContext from "./Context/TablePosition";
import ConstraintPage from "./Pages/ConstraintPage";
import BetaScreenPage from "./Pages/BetaScreenPage";
import Plc from "./Pages/Plc";

function App(
  { msaLInstance }
) {
  // const [socket, setSocket] = useState(new WebSocket("wss://bsh.api-boyahane.smartopt.com.tr/ws/hang-socket-server/"))
  // const socket = new WebSocket("ws://127.0.0.1:8000/ws/hang-socket-server/")
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [currentUrl, setCurrentUrl] = useState(localStorage.getItem("urlpaintshop") || "")
  const [user, setUser] = useState({
    isLogin: isAuthenticated,
    accountDetail: instance?.getActiveAccount(),
  });

  const userContextData = {
    user,
    setUser,
  };

  const tableContextData = {
    setCurrentUrl
  }

  return (
    <div className="App">
      <MsalProvider instance={msaLInstance}>
        <UserContext.Provider value={userContextData}>
          <TableContext.Provider value={tableContextData} >

            {
              !user.isLogin ?
                <Routes>
                  <Route path="/" element={<SuspenseContainer JsxElement={<FirstPage />} />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>

                : (() => {
                  switch (user?.accountDetail?.idTokenClaims?.roles[0]) {
                    case "ps_admin": return <>
                      <HomeLayout>
                        <Routes>
                          <Route path="/" element={<Navigate to={currentUrl === "" ? "/asma" : currentUrl} replace />} />
                          <Route path="/asma" element={<SuspenseContainer JsxElement={<Asma />} />} />
                          <Route path="/asma-spv" element={<SuspenseContainer JsxElement={<AsmaSPV />} />} />
                          <Route path="/toplama" element={<SuspenseContainer JsxElement={<Toplama />} />} />
                          <Route path="/rework-hurda" element={<SuspenseContainer JsxElement={<ReworkHurda />} />} />
                          <Route path="/stok-seviyeleri" element={<SuspenseContainer JsxElement={<GovdeOnPage />} />} />
                          <Route path="/alarm" element={<SuspenseContainer JsxElement={<Alert />} />} />
                          <Route path="/dashboard" element={<SuspenseContainer JsxElement={<NewDashboard />} />} />
                          <Route path="/dashboard2" element={<SuspenseContainer JsxElement={<Dashboard2 />} />} />
                          <Route path="/kontrol-gecmisi" element={<SuspenseContainer JsxElement={<ControlHistory />} />} />
                          <Route path="/ariza-gecmisi" element={<SuspenseContainer JsxElement={<FailureHistory />} />} />
                          <Route path="/uretim" element={<SuspenseContainer JsxElement={<StokPage />} />} />
                          <Route path="/vardiya-planı" element={<SuspenseContainer JsxElement={<VardiyaPlanPage />} />} />
                          <Route path="/alarm2" element={<SuspenseContainer JsxElement={<Alert2 />} />} />
                          <Route path="/status-info" element={<SuspenseContainer JsxElement={<StatusInfo />} />} />
                          <Route path="/help-center" element={<SuspenseContainer JsxElement={<NewReport />} />} />
                          <Route path="/constraints" element={<SuspenseContainer JsxElement={<ConstraintPage />} />} />
                          <Route path="/beta-screen" element={<SuspenseContainer JsxElement={<BetaScreenPage />} />} />
                          <Route path="/plc" element={<SuspenseContainer JsxElement={<Plc />} />} />

                          <Route path="*" element={<Navigate to="/asma" />} />
                        </Routes>
                      </HomeLayout>
                    </>
                    case "ps_yonetim": return <>
                      <HomeLayout>
                        <Routes>
                          <Route path="/" element={<Navigate to={currentUrl === "" ? "/asma-spv" : currentUrl} replace />} />
                          <Route path="/asma-spv" element={<SuspenseContainer JsxElement={<AsmaSPV />} />} />
                          <Route path="/dashboard" element={<SuspenseContainer JsxElement={<NewDashboard />} />} />
                          <Route path="/dashboard2" element={<SuspenseContainer JsxElement={<Dashboard2 />} />} />
                          <Route path="/uretim" element={<SuspenseContainer JsxElement={<StokPage />} />} />
                          <Route path="/vardiya-planı" element={<SuspenseContainer JsxElement={<VardiyaPlanPage />} />} />
                          <Route path="/status-info" element={<SuspenseContainer JsxElement={<StatusInfo />} />} />
                          <Route path="/help-center" element={<SuspenseContainer JsxElement={<NewReport />} />} />
                          <Route path="*" element={<Navigate to="/asma-spv" />} />
                        </Routes>
                      </HomeLayout>
                    </>
                    case "ps_planlama": return <>
                      <HomeLayout>
                        <Routes>
                          <Route path="/" element={<Navigate to={currentUrl === "" ? "/asma-spv" : currentUrl} replace />} />
                          <Route path="/asma-spv" element={<SuspenseContainer JsxElement={<AsmaSPV />} />} />
                          <Route path="/rework-hurda" element={<SuspenseContainer JsxElement={<ReworkHurda />} />} />
                          <Route path="/stok-seviyeleri" element={<SuspenseContainer JsxElement={<GovdeOnPage />} />} />
                          <Route path="/dashboard" element={<SuspenseContainer JsxElement={<NewDashboard />} />} />
                          <Route path="/dashboard2" element={<SuspenseContainer JsxElement={<Dashboard2 />} />} />
                          <Route path="/kontrol-gecmisi" element={<SuspenseContainer JsxElement={<ControlHistory />} />} />
                          <Route path="/ariza-gecmisi" element={<SuspenseContainer JsxElement={<FailureHistory />} />} />
                          <Route path="/uretim" element={<SuspenseContainer JsxElement={<StokPage />} />} />
                          <Route path="/vardiya-planı" element={<SuspenseContainer JsxElement={<VardiyaPlanPage />} />} />
                          <Route path="/help-center" element={<SuspenseContainer JsxElement={<NewReport />} />} />
                          <Route path="/status-info" element={<SuspenseContainer JsxElement={<StatusInfo />} />} />
                          <Route path="*" element={<Navigate to="/asma-spv" />} />
                        </Routes>
                      </HomeLayout>
                    </>
                    case "ps_govde": return <>
                      <HomeLayout>
                        <Routes>
                          <Route path="/" element={<Navigate to={currentUrl === "" ? "/asma" : currentUrl} replace />} />
                          <Route path="/asma" element={<SuspenseContainer JsxElement={<Asma />} />} />
                          <Route path="/alarm" element={<SuspenseContainer JsxElement={<Alert />} />} />
                          <Route path="/dashboard" element={<SuspenseContainer JsxElement={<NewDashboard />} />} />
                          <Route path="/dashboard2" element={<SuspenseContainer JsxElement={<Dashboard2 />} />} />
                          <Route path="/kontrol-gecmisi" element={<SuspenseContainer JsxElement={<ControlHistory />} />} />
                          <Route path="/ariza-gecmisi" element={<SuspenseContainer JsxElement={<FailureHistory />} />} />
                          <Route path="/uretim" element={<SuspenseContainer JsxElement={<StokPage />} />} />
                          <Route path="/help-center" element={<SuspenseContainer JsxElement={<NewReport />} />} />
                          <Route path="/status-info" element={<SuspenseContainer JsxElement={<StatusInfo />} />} />
                          <Route path="*" element={<Navigate to="/asma" />} />
                        </Routes>
                      </HomeLayout>
                    </>
                    case "ps_boyahane": return <>
                      <HomeLayout>
                        <Routes>
                          <Route path="/" element={<Navigate to={currentUrl === "" ? "/asma-spv" : currentUrl} replace />} />
                          <Route path="/asma-spv" element={<SuspenseContainer JsxElement={<AsmaSPV />} />} />
                          <Route path="/toplama" element={<SuspenseContainer JsxElement={<Toplama />} />} />
                          <Route path="/rework-hurda" element={<SuspenseContainer JsxElement={<ReworkHurda />} />} />
                          <Route path="/stok-seviyeleri" element={<SuspenseContainer JsxElement={<GovdeOnPage />} />} />
                          <Route path="/dashboard" element={<SuspenseContainer JsxElement={<NewDashboard />} />} />
                          <Route path="/dashboard2" element={<SuspenseContainer JsxElement={<Dashboard2 />} />} />
                          <Route path="/kontrol-gecmisi" element={<SuspenseContainer JsxElement={<ControlHistory />} />} />
                          <Route path="/ariza-gecmisi" element={<SuspenseContainer JsxElement={<FailureHistory />} />} />
                          <Route path="/uretim" element={<SuspenseContainer JsxElement={<StokPage />} />} />
                          <Route path="/alarm2" element={<SuspenseContainer JsxElement={<Alert2 />} />} />
                          <Route path="/help-center" element={<SuspenseContainer JsxElement={<NewReport />} />} />
                          <Route path="/status-info" element={<SuspenseContainer JsxElement={<StatusInfo />} />} />
                          <Route path="/vardiya-planı" element={<SuspenseContainer JsxElement={<VardiyaPlanPage />} />} />
                          <Route path="*" element={<Navigate to="/asma-spv" />} />
                        </Routes>
                      </HomeLayout>
                    </>
                  }
                })()

            }
          </TableContext.Provider>
        </UserContext.Provider>
      </MsalProvider>
    </div>
  );

}

function reloadPageAtSpecificTime(hour, minute, second) {
  var now = new Date();
  var targetTime = new Date();
  targetTime.setHours(hour);
  targetTime.setMinutes(minute);
  targetTime.setSeconds(second);

  var timeToReload = targetTime.getTime() - now.getTime();
  if (timeToReload < 0) {
    // Hedef saat bugün için geçmişse, bir sonraki gün aynı saatte yeniden yükleme yapılması gerekiyor
    targetTime.setDate(targetTime.getDate() + 1);
    timeToReload = targetTime.getTime() - now.getTime();
  }

  setInterval(function () {
    window.location.reload();
  }, timeToReload);
}

reloadPageAtSpecificTime(20, 0, 0);

export default App;
