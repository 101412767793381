import { Button, Table, Popconfirm, message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'
import MalzemeKoduModal from '../ModalComponent/ConstraintModal/MalzemeKoduModal'

const MalzemeKodu = () => {

    const [allData, setAllData] = useState([])
    const [modifiedData, setModifiedData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [rule, setRule] = useState("")
    const [getColor, setGetColor] = useState([])
    const [bodyMaterialType, setBodyMaterialType] = useState([])

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = async () => {
        await axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getBodyMaterial/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setAllData(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [refreshPage])

    const handleDelete = (row) => {
        axios.delete(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/deleteBodyMaterial/${row.id}`, {
            headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`
            }
        })
            .then(res => {
                setRefreshPage(!refreshPage);
            })
            .catch(err => {
                console.error("Silme işlemi sırasında hata oluştu:", err);
            });
    };

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            tanim: item?.tanim,
            malzeme_kodu: item?.malzeme_kodu,
            min_inventory_periodically: item?.min_inventory_periodically,
            put_inventory_before_demand: item?.put_inventory_before_demand,
            production_rate_according_demand: item?.production_rate_according_demand,
            par_obj_func_stop_exceeding_demand: item?.par_obj_func_stop_exceeding_demand,
            critical: item?.critical,
            end_min_inventory: item?.end_min_inventory,
            end_max_inventory: item?.end_max_inventory,
            hour_delayed: item?.hour_delayed,
            produce_close_demand: item?.produce_close_demand,
            body_color: item?.body_color?.description,
            body_material_type: item?.body_material_type?.description,
            body_colorid: item?.body_color?.id,
            body_material_typeid: item?.body_material_type?.id,
            is_service: item?.is_service,
            id: item?.id

        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const handleClick = (row) => {
        setSelectedRow(row)
        setModalOpen(true)
        setRule("changeOrder")
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getColor/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setGetColor(res.data))
            .catch(err => console.log(err))

        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getBodyMaterialType/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setBodyMaterialType(res.data))
            .catch(err => console.log(err))
    }

    const columns = [
        {
            title: "Malzeme Kodu",
            dataIndex: "malzeme_kodu",
            key: "malzeme_kodu",
            align: "center",
            width: 150,
        },
        {
            title: "Tanım",
            dataIndex: "tanim",
            key: "tanim",
            align: "center",
            width: 150,
        },
        {
            title: "Sürekli Minimum Stok",
            dataIndex: "min_inventory_periodically",
            key: "min_inventory_periodically",
            width: 150,
            align: "center",

        },

        {
            title: "Talepten Önce Stoğa Girme Saati",
            dataIndex: "put_inventory_before_demand",
            key: "put_inventory_before_demand",
            align: "center",
            width: 180,
        },

        {
            title: "Talebe göre Üretim Oranı",
            dataIndex: "production_rate_according_demand",
            key: "production_rate_according_demand",
            align: "center",
            width: 180,
        },
        {
            title: "Talebi Aşmama Amaç Fonksiyonu Katsayı Parametresi",
            dataIndex: "par_obj_func_stop_exceeding_demand",
            key: "par_obj_func_stop_exceeding_demand",
            align: "center",
            width: 200,
        },
        {
            title: "Ürün Kritik mi?",
            dataIndex: "critical",
            key: "critical",
            align: "center",
            width: 180,
            render: (text, record) => (
                <span>{record.critical ? "Yes" : "No"}</span>
            ),
        },
        {
            title: "Bitiş Minimum Stoğu",
            dataIndex: "end_min_inventory",
            key: "end_min_inventory",
            align: "center",
            width: 180,
        },
        {
            title: "Bitiş Maksimum Stoğu",
            dataIndex: "end_max_inventory",
            key: "end_max_inventory",
            align: "center",
            width: 180,
        },
        {
            title: "Ürün Kaç Saat Ertelensin?",
            dataIndex: "hour_delayed",
            key: "hour_delayed",
            align: "center",
            width: 180,
        },
        {
            title: "Talebe Yakın Üretilsin mi?",
            dataIndex: "produce_close_demand",
            key: "produce_close_demand",
            align: "center",
            width: 200,
            render: (text, record) => (
                <span>{record.produce_close_demand ? "Yes" : "No"}</span>
            ),
        },
        {
            title: "Gövde Rengi",
            dataIndex: "body_color",
            key: "body_color",
            align: "center",
            width: 200,

        },
        {
            title: "Gövde Tipi",
            dataIndex: "body_material_type",
            key: "body_material_type",
            align: "center",
            width: 200,

        },
        {
            title: "Servis Ürünü mü?",
            dataIndex: "is_service",
            key: "is_service",
            align: "center",
            width: 200,
            render: (text, record) => (
                <span>{record.is_service ? "Yes" : "No"}</span>
            ),
        },
        {
            title: "İşlem",
            dataIndex: "islem",
            key: "islem",
            align: "center",
            width: 150,
            render: (record, row) => {
                return (
                    <div className='flex flex-row gap-3 justify-center'>
                        <Button
                            onClick={() => handleClick(row)}
                            style={{ fontFamily: "sans-serif" }}
                            className='text-white bg-smartopt font-semibold'
                        >
                            Değiştir
                        </Button>
                        <Popconfirm
                            title="Sil"
                            description="Silmek istediğinize emin misiniz?"
                            onConfirm={() => {
                                handleDelete(row)
                                message.success('İşlem Başarılı');
                            }}
                            onCancel={() => console.log("cancel")}
                            okText="Evet"
                            cancelText="Hayır"
                            okType="primary"
                        >
                            <Button
                                style={{ fontFamily: "sans-serif" }}
                                className='text-white bg-smartopt font-semibold'
                            >
                                Sil
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
        },


    ];


    return (
        <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            <MalzemeKoduModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modifiedData={modifiedData}
                selectedRow={selectedRow}
                rule={rule}
                setRefreshPage={setRefreshPage}
                refreshPage={refreshPage}
                getColor={getColor}
                bodyMaterialType={bodyMaterialType}
            />
            <div className="flex p-4 flex-col h-full gap-2">
                <div className='flex w-max bg-smartopt rounded-lg'>
                    <Button className='text-white font-semibold' onClick={() => {
                        setModalOpen(true)
                        setRule("newOrder")
                        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getColor/all`, {
                            auth: {
                                username: username,
                                password: password
                            }
                        })
                            .then(res => setGetColor(res.data))
                            .catch(err => console.log(err))

                        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getBodyMaterialType/all`, {
                            auth: {
                                username: username,
                                password: password
                            }
                        })
                            .then(res => setBodyMaterialType(res.data))
                            .catch(err => console.log(err))
                    }}>
                        Malzeme Kodu Ekle
                    </Button>
                </div>

                <div className="tableAsma flex h-full overflow-auto w-full">
                    <Table
                        bordered
                        sticky
                        dataSource={modifiedData}
                        // loading={loading}
                        // rowClassName={(record, index) => {
                        //     //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
                        //     // console.log("record", record);
                        //     return record.is_started_asma === true ||
                        //         record.is_completed_asma === true
                        //         ? "table-first-item table-height"
                        //         : index % 2 === 0
                        //             ? "table-row-light table-height"
                        //             : "table-row-dark table-height";
                        // }}
                        // onChange={handleTableChange}
                        columns={columns}
                        scroll={{ x: 700 }}
                        pagination={{ pageSize: 6 }}
                    // scrollToFirstRowOnChange={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default MalzemeKodu