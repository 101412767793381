import { useContext, useEffect, useState } from "react";
import { Table, Button, notification, message } from "antd";
import { Excel } from "antd-table-saveas-excel";
import UserContext from "../Context/UserContext";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";
import { apiUrl } from "../config";
import axios from "axios";
import { CheckCircleTwoTone, WarningTwoTone, LoadingOutlined, SyncOutlined } from '@ant-design/icons';

export default function BetaScreen() {
  const [modifiedData, setModifiedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { user } = useContext(UserContext)
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  const getData = () => {
    // setLoading(true)
    axios.get(`${apiUrl}/beta/getBSHStartStopResults`, {
      auth: {
        username: username,
        password: password
      }
    })
      .then((response) => setAllData(response.data))
      .catch((error) => console.log(error))
    // setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      order_nr: item?.order_nr,
      period: item?.period,
      date: item?.date,
      toplama_period: item?.toplama_period,
      production_occurs: item?.toplama_period,
      consecutive_stop: item?.consecutive_stop,
      rework: item?.rework,
      rework_summary: item?.rework_summary,
      uretim: item?.uretim,
      siparis: item?.siparis,
      renk: item?.renk,
      white_production: item?.white_production,
      silver_production: item?.silver_production,
      govde: item?.govde,
      single_gormeme: item?.single_gormeme,
      total_hds_stok: item?.total_hds_stok,
      konveyor_uzeri: item?.konveyor_uzeri,
      is_color_change: item?.is_color_change,
      is_first_stop_occured: item?.is_first_stop_occured,
      is_govde_change: item?.is_govde_change,
      is_govde_on_change: item?.is_govde_on_change,
      is_completed_asma: item?.is_completed_asma,
      is_completed_toplama: item?.is_completed_toplama,
      is_started_asma: item?.is_started_asma,
      is_started_toplama: item?.is_started_toplama,
      actual_production_asma: item?.actual_production_asma,
      actual_production_toplama_govde: item?.actual_production_toplama_govde,
      actual_production_toplama_govde_on: item?.actual_production_toplama_govde_on,
      total_amount_toplama_govde: item?.total_amount_toplama_govde,
      total_amount_solid_govde: item?.total_amount_solid_govde,
      static_rework_amount_toplama_govde: item?.static_rework_amount_toplama_govde,
      static_rework_amount_toplama_govde_on: item?.static_rework_amount_toplama_govde_on,
      dynamic_rework_amount_toplama_govde: item?.dynamic_rework_amount_toplama_govde,
      dynamic_rework_amount_toplama_govde_on: item?.dynamic_rework_amount_toplama_govde_on,
      junk_amount_toplama_govde: item?.junk_amount_toplama_govde,
      is_external_added: item?.is_external_added,
      is_manuel_double: item?.is_manuel_double,
      sequence: item?.sequence,
      batch_date: item?.order_batch?.batch_date,
      is_started: item?.order_batch?.is_started,
      is_completed: item?.order_batch?.is_completed,
      date: item?.date

    }));

    setModifiedData([...dataList]);
  }, [allData]);

  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  const columns = [


    {
      title: "SmartOpt Sipariş Numarası",
      dataIndex: "order_nr",
      key: "order_nr",
      align: "center",
      width: 120,
    },
    {
      title: "Spv No",
      dataIndex: "sequence",
      key: "sequence",
      width: 90,
      align: "center",

    },

    {
      title: "Saat",
      dataIndex: "period",
      key: "period",
      align: "center",
      width: 100,
    },

    {
      title: "Toplama Periyod",
      dataIndex: "toplama_period",
      key: "toplama_period",
      align: "center",
      width: 150,
    },
    {
      title: "Sipariş İçeriği",
      dataIndex: "siparis",
      key: "siparis",
      align: "center",
      width: 180,
    },
    {
      title: "Hedef",
      dataIndex: "uretim",
      key: "uretim",
      align: "center",
      width: 150,

    },

  ];


  const handleClickExcel = () => {
    const excel = new Excel();
    let newColumns = columns.filter(
      (column) => column.title !== "Spv No" && column.title !== "Sayaç"
    );

    newColumns.push({ title: "Spv No", dataIndex: "spvNo" });
    newColumns.push({ title: "Sayaç", dataIndex: "sayac" });

    //* count kısmıda sayac oldugundan onu exceldeki tablodan cıkarıyoruz.
    const newData = modifiedData.filter((item) => !item.count);

    excel
      .addSheet("test")
      .addColumns(newColumns)
      .addDataSource(modifiedData, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, value, icon, style) => {
    api.info({
      message: `Sistem Bilgisi`,
      description:
        `${value}`,
      placement,
      icon: icon || <CheckCircleTwoTone twoToneColor="#52c41a" />,
      style: style
    });
  };

  return (
    <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
      {contextHolder}
      <div className="flex w-full flex-col h-full mt-4 gap-2">
        <div className='flex w-max rounded-lg gap-2 items-center'>
          <Button className='text-white font-semibold w-32 bg-smartopt'
            onClick={() => {
              axios.post(`${apiUrl}/beta/createRunRequest`, null, {
                auth: {
                  username: username,
                  password: password
                }
              })
                .then(res => openNotification('topRight', (res.data), <CheckCircleTwoTone twoToneColor="green" />)
                )

            }}
          >
            Çalıştır
          </Button>
          <Button className='text-white font-semibold  bg-smartopt'
            onClick={() => {
              axios.post(`${apiUrl}/beta/handleMigration`, null, {
                auth: {
                  username: username,
                  password: password
                }
              })
                .then(res => openNotification('topRight', (res.data), <CheckCircleTwoTone twoToneColor="green" />)
                )

            }}
          >
            Ana Sisteme Aktar
          </Button>
          <p className="ml-4"><b>Son Güncellenme Tarihi : </b> {modifiedData[0]?.batch_date?.slice(0, 10) + " " + modifiedData[0]?.batch_date?.slice(11, 16)}</p>
        </div>
        <div className="tableAsma flex h-full overflow-auto w-full">
          <Table
            bordered
            sticky
            dataSource={modifiedData}
            rowClassName={(record, index) => {
              return record.is_started_asma === true ||
                record.is_completed_asma === true
                ? "table-first-item table-height"
                : index % 2 === 0
                  ? "table-row-light table-height"
                  : "table-row-dark table-height";
            }}
            onChange={handleTableChange}
            columns={columns}
            scroll={{ x: "6000" }}
            pagination={{ pageSize: 6 }}
          // scrollToFirstRowOnChange={true}
          />
        </div>
      </div>
    </div>
  );
}
